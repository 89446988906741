import { FormattedMessage } from 'react-intl'

import { NavItemType } from 'types/menu'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'

import { Storefront, Dashboard } from '@mui/icons-material'
import ReviewsBadge from 'components/ReviewsBadge'
import { ROUTES } from 'utils/constants/routes'

const main: NavItemType = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/',
      icon: Dashboard,
    },
    {
      id: 'business',
      title: <FormattedMessage id="business" />,
      type: 'item',
      url: ROUTES.BUSINESSES,
      icon: Storefront,
      roles: ['admin', 'moderator'],
    },
    {
      id: 'reviews',
      title: <ReviewsBadge />,
      type: 'item',
      url: ROUTES.REVIEWS,
      icon: ChatOutlinedIcon,
      roles: ['admin', 'moderator'],
    },
  ],
}

export default main
